/* AppPortalRedesign.css */

.my-app-portal-container {
    width: 100%;
    height: 55vh;
    border: 5px solid #FF6D40;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .my-app-portal-header {
    background-color: #FF6D40;
    color: white;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
  }
  
  .my-app-portal-content {
    overflow-y: auto;
    height: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  
  /* Scrollbar styling (WebKit) */
  .my-app-portal-content::-webkit-scrollbar {
    width: 12px;
  }
  
  .my-app-portal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }
  
  .my-app-portal-content::-webkit-scrollbar-thumb {
    background-color: #FF6D40;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
  }
  
  /* For Firefox */
  .my-app-portal-content {
    scrollbar-width: thin;
    scrollbar-color: #FF6D40 #f1f1f1;
  }
  
  /* No apps available styling */
  .my-app-portal-no-apps {
    text-align: center;
    color: #FF6D40;
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
  }
  