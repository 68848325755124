/* src/InactivityTimer.css */

/* Fade-in animation for the overlay */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Slide-down animation for the modal box */
  @keyframes slideDown {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Hover and active states for the button */
  .button:hover {
    background-color: #e04e00; /* Darker SRI Orange */
  }
  
  .button:active {
    transform: scale(0.98);
  }
  
  /* Styles for the alert container (overlay) */
  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 92, 0, 0.85); /* SRI Orange with higher opacity for better visibility */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease-in-out;
    padding: 10px; /* Padding for smaller screens */
    box-sizing: border-box;
  }
  
  /* Styles for the alert box (modal) */
  .alert-box {
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.5s ease-in-out;
    position: relative;
  }
  
  /* Styles for the title */
  .title {
    color: #FF5C00; /* SRI Orange */
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  /* Styles for the message */
  .message {
    font-size: 1rem;
    color: #333;
    margin-bottom: 25px;
    line-height: 1.5;
  }
  
  /* Styles for the button */
  .button {
    background-color: #FF5C00; /* SRI Orange */
    color: #fff;
    border: none;
    padding: 12px 30px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Responsive Design for Mobile Devices */
  @media (max-width: 480px) {
    .alert-box {
      padding: 20px;
    }
    .title {
      font-size: 1.5rem;
    }
    .message {
      font-size: 0.95rem;
    }
    .button {
      padding: 10px 25px;
      font-size: 0.95rem;
    }
  }
  