.user-management-container {
    width: 100%;
    height: 65vh;
    overflow: auto;
    border: 5px solid #FF6D40;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
}

.header {
    background-color: #FF6D40;
    color: white;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
}

.search-input {
    padding: 10px;
}



.organization-info {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
}

.app-access-section {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
}

.county-state-select {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.user-info {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center ;
}



.form.label {
    background-color: #fae0d9;
    border-radius: 10px 10px 0 0; /* Round top left and top right */
    padding: 5px 10px;
    margin-bottom: 0; /* Removes gap between label and input */
    width: fit-content;
    font-size: 12px;
  }

  .form.group {
    padding: 10px !important;
    
  }

  .infobox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    width: 100%;

    }

/* Primary color for buttons and possibly other elements */
.btn-primary {
    background-color: #FF6D40;
    border-color: #FF6D40;
}

/* Style for disabled dropdown */
.small-dropdown.disabled .small-dropdown-toggle {
    background-color: #e9ecef;
    color: #6c757d;
}

.small-dropdown .small-dropdown-toggle {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem; 
}

.small-dropdown .small-dropdown-menu {
    font-size: 0.875rem; 
}

/* Dropdown button with 'orange' class */
.orange.dropdown-toggle {
    background-color: #FF6D40 !important;
    color: #ffffff !important; /* Text color */
    border-color: #FF6D40 !important; /* Border color */
}
  
/* Hover effect on dropdown toggle with 'orange' class */
.orange.dropdown-toggle:hover {
    background-color: #FF884D !important; /* Adjusted color for hover */
    border-color: #FF884D !important; /* Adjusted border color for hover */
}

/* Dropdown items under 'orange' dropdown */
.orange .dropdown-menu .dropdown-item {
    color: #333333 !important; /* Text color */
}

/* Hover effect on dropdown items under 'orange' dropdown */
.orange .dropdown-menu .dropdown-item:hover {
    background-color: #FF6D40 !important;
    color: #ffffff !important; /* Text color */
}

/* Custom switch for 'orange' class if needed */
.orange .custom-switch .form-check-input:checked + .form-check-label::before {
    background-color: #FF6D40 !important;
}

.full-component-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    text-align: center;
    background-color: #FF884D;
    color: white;
}

.full-component-message.error {
    background-color: white;
    color: red;
}

.full-component-message-content {
    font-size: 24px;
    font-weight: bold;
    padding: 20px;
    border-radius: 10px;
}


  