/* src/components/SaleRegistrationsComponent.css */

/* Container */
.sale-reg-container {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  .sale-reg-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sale-reg-header h2 {
    font-size: 2rem;
    color: #333;
  }
  
  /* Filters */
  .sale-reg-filters {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .sale-reg-dropdown {
    width: 100%;
    max-width: 300px;
  }
  
  .sale-reg-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF6D40;
    border: none;
  }
  
  .sale-reg-dropdown-toggle:hover,
  .sale-reg-dropdown-toggle:focus {
    background-color: #e65c33;
    border: none;
  }
  
  .sale-reg-icon {
    margin-right: 8px;
    color: #fff;
  }
  
  .sale-reg-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .sale-reg-dropdown-menu .dropdown-item:hover {
    background-color: #FF6D40;
    color: white;
  }
  
  /* Table */
  .sale-reg-table-responsive {
    overflow-x: auto;
  }
  
  .sale-reg-table {
    width: 100%;
    min-width: 600px;
  }
  
  .sale-reg-table th,
  .sale-reg-table td {
    text-align: center;
    vertical-align: middle;
  }
  
  .sale-reg-user-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sale-reg-user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .sale-reg-user-email {
    flex: 1;
    margin-right: 10px;
    font-size: 0.9rem;
    color: #555;
    word-break: break-all;
  }
  
  .sale-reg-unregister-btn {
    flex-shrink: 0;
  }
  
  /* Add Registration Button */
  .sale-reg-add-btn {
    width: 100%;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF6D40;
    border: none;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .sale-reg-add-btn:hover,
  .sale-reg-add-btn:focus {
    background-color: #e65c33;
    color: white;
  }
  
  .sale-reg-add-btn .fa-user-plus {
    margin-right: 5px;
  }
  
  /* Pagination */
  .sale-reg-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .sale-reg-pagination-item {
    cursor: pointer;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sale-reg-container {
      padding: 10px;
    }
  
    .sale-reg-header h2 {
      font-size: 1.5rem;
    }
  
    .sale-reg-dropdown {
      max-width: 100%;
    }
  
    .sale-reg-user-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .sale-reg-user-email {
      margin-right: 0;
      margin-bottom: 5px;
    }
  
    .sale-reg-add-btn {
      max-width: 100%;
    }
  }
  