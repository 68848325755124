/* LienAssistPage.module.css */

.banner {
    width: 100%;
    height: 25rem;
    background-image: url("../images/lap.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    align-items: center;
}

.bannerTitle {
    color: White;
    margin-left: 10rem;
}

.contentWrapper {
    width: 100%;
    min-height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 7rem;
    margin-top: 1rem;
}

.leftContent {
    width: 50%;
    color: #F26B3F;
    padding-right: 2rem;
}



.listWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.btnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .contentWrapper {
        flex-direction: column;
        width: 100%;
    }

    .leftContent, .rightContent {
        width: 100%;
        align-items: center;
        justify-content: center;


    }

    .leftContent {
        padding-right: 0;
    }

    .contentWrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
