/* MessageComponentRedesign.css */

/* 
  Outer container:
  - 5px SRI-orange border 
  - Rounded corners
  - White interior
  - Minimal/no padding so messages appear flush
*/
.my-message-component-container {
    width: 100%;
    height: 55vh; /* adjustable height */
    background-color: #fff;
    border: 5px solid #FF6D40; /* SRI Orange border */
    border-radius: 10px;       /* Rounded corners */
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden; /* Hide any overflow mismatch */
  }
  
  /* 
    Header:
    - Rounded top corners (matches container)
    - SRI Orange background
    - Minimal padding
  */
  .my-message-component-header {
    background-color: #FF6D40;
    color: #fff;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    /* match the container's radius on top */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  /* Left/Center/Right sections for the header content */
  .my-message-component-header-left,
  .my-message-component-header-middle,
  .my-message-component-header-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Left aligns to start, right to end, middle center */
  .my-message-component-header-left {
    justify-content: flex-start;
  }
  .my-message-component-header-right {
    justify-content: flex-end;
  }
  
  /* Title styling */
  .my-message-component-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
  }
  
  /* Optional style for your filter button group */
  .btn-group-custom .btn {
    margin: 0 2px; /* small spacing between filter buttons */
  }
  
  /* Sort button tweaks */
  .sort-button {
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
  }
  
  /* 
    Content area:
    - Occupies remaining vertical space
    - Scrollable
    - Flush edges (no big padding)
  */
  .my-message-component-content {
    flex: 1;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  /* Slim, modern scrollbar (WebKit) */
  .my-message-component-content::-webkit-scrollbar {
    width: 8px;
  }
  .my-message-component-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  .my-message-component-content::-webkit-scrollbar-thumb {
    background-color: #FF6D40;
    border-radius: 4px;
  }
  /* For Firefox */
  .my-message-component-content {
    scrollbar-width: thin;
    scrollbar-color: #FF6D40 #f1f1f1;
  }
  
  /* 
    List container for messages:
    - No default bootstrap borders
    - Flush to container edges
  */
  .my-message-list {
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
  
  /* 
    Individual message item:
    - Minimal padding, flush horizontally
    - Subtle bottom border
  */
  .my-message-component-item {
    border: none;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 1rem;
    display: block;
    font-size: 0.9rem;
    box-sizing: border-box;
  }
  
  /* Distinguish read/unread with subtle color changes */
  .my-message-read {
    background-color: #f9f9f9 !important; /* Light grey for read */
  }
  .my-message-unread {
    background-color: #ffffff !important; /* White for unread */
  }
  
  /* 
    Row for each message:
    - Left side (icon/text)
    - Right side (action buttons)
  */
  .my-message-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Left side: read/unread icon + message info */
  .my-message-left {
    display: flex;
    align-items: center;
    gap: 0.75rem; /* space between icon and text */
  }
  .icon-read {
    color: #6c757d; /* grey envelope for read */
    font-size: 1.2rem;
  }
  .icon-unread {
    color: #007bff; /* blue envelope for unread */
    font-size: 1.2rem;
  }
  .my-message-info {
    display: flex;
    flex-direction: column;
  }
  .my-message-text {
    font-weight: 500;
    margin-bottom: 2px;
  }
  .my-message-date {
    color: #888;
    font-size: 0.75rem;
  }
  
  /* Right side: action buttons (read/unread, delete) */
  .my-message-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .action-button {
    font-size: 0.8rem;
    width: 32px;       /* optional: fix width for consistent button sizing */
    height: 32px;      /* optional: fix height if you want perfect squares */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* 
    "No notifications" 
  */
  .my-message-no-notifications {
    color: #FF6D40;
    font-size: 1.2rem;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* 
    Responsive adjustments for smaller screens
  */
  @media (max-width: 768px) {
    .my-message-component-header {
      flex-direction: column;
      align-items: flex-start;
      padding: 0.75rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  
    .my-message-component-header-left,
    .my-message-component-header-middle,
    .my-message-component-header-right {
      justify-content: flex-start;
      margin-bottom: 0.5rem;
    }
  
    .my-message-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  
    /* Adjust content height for new header size if needed */
    .my-message-component-content {
      height: calc(100% - 120px);
    }
  }
  