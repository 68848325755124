.headerSection {
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.headerTitle {
    color: White;
    margin-left: 10rem;
}

.contentContainer {
    width: 100%;
    min-height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 7rem;
    padding-right: 7rem;
}

.leftSide {
    width: 50%;
    color: #F26B3F;
}

.leftSideTitle {
    margin-right: 2rem;
}

.rightSide {
    width: 50%;
    margin-left: 0;
}

.requestSection {
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.btnStyle {
    background-color: white !important;
    color: black !important;
    margin: 2rem !important;
    width: 15rem !important;
    height: 3rem !important;
}

.btnStyleBlack {
    background-color: #F26B3F !important;
    color: white !important;
    margin: 2rem !important;
    width: 15rem !important;
    height: 3rem !important;
}

.whySRIContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.whySRISide {
    width: 45%;
    display: grid;
    grid-template-columns: 1fr;
}

/* .whySRISide ul {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    align-items: start;
    list-style-type: none;
    padding: 0;
}

.whySRISide ul > b {
    grid-column: 1;
}

.whySRISide ul > li {
    grid-column: 2;
} */

.imgStyle {
    width: 20rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.contactSection {
    border-top: 1px solid #F26B3F;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25rem;
    color: #F26B3F;
}

.BottomContentContainer {
    display: flex;
    flex-direction: column;
}

.partnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .contentContainer, .whySRISide {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .leftSide, .rightSide {
        width: 100% !important;
        text-align: center; /* optional, for centering text on mobile */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0 !important;
    }

    .headerTitle, .leftSideTitle {
        margin-left:0;
        text-align: center;
    }

    .whySRIContainer {
        flex-direction: column;
        align-items: center;
    }

    .contactContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imgStyle {
        display: none  ;
    }
    .partnerContainer
    {
        flex-direction: column;
        align-items: center;
    }
    .leftSideTitle {
        margin-left: 0rem;
        text-align: center;
    }
   .requestSection {
        width: 100%;
   }

   .BottomContentContainer {
       width: 100%;
   }

   .headerSection {
       width: 100%;
   }
}
