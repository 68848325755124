/* Investing.module.css */

.image1Container {
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    align-items: center;
}

.h1Container {
    color: white;
    margin-left: 10rem;
}

.flexContainer {
    width: 100%;
    min-height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 7rem;
    padding-right: 7rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.width40 {
    width: 40%;
    color: #F26B3F;
}

.marginRight1 {
    margin-right: 1rem;
}

.blackColor {
    color: black;
}

.width60 {
    width: 60%;
}

.image2Container {
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;
}

.searchButton {
    background-color: white !important;
    color: black !important;
    margin: 2rem;
    width: 15rem;
    height: 3rem;
}


@media (max-width: 768px) {
    .flexContainer {
        flex-direction: column;
    }

    .width40 {
        width: 100%;
    }

    .width60 {
        width: 100%;    
    }

    .image2Container {
        flex-direction: column;
    }

    }