.centered-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.centered-card {
    margin: 0.5rem;
    display: flex;
    justify-content: center;
}

.card {
    width: 19rem;
    height: 300px; 
}

.map-container {
    height: 100px;
    width: 100%;
    position: relative;
}

.modal-map-container {
    width: 100%;
    height: 400px;
}

.card-text-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.card-text-label {
    font-weight: bold;
    white-space: nowrap;
    margin-right: 8px;
}

.card-text-value {
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}

.card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.truncate {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.copy-button {
    margin-left: 8px;
}

.sale-id-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
    font-size: small;
}

@keyframes rotateRainbow {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

.rainbow-border {
    border: 3px solid transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white), linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: rotateRainbow 2s linear infinite;
}

.rainbow-border-button {
    position: relative;
    padding: 5px;
    margin: 2px;
}

.rainbow-border-button::after {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 5px;
    z-index: -1;
    background-image: linear-gradient(white, white), linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: rotateRainbow 2s linear infinite;
}

.property-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
}

.property-card {
    width: 19rem;
    height: 440px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    width: 100%;
}

.county-details {
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: box-shadow 0.3s ease;
}

.county-details:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.county-details p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: 5px;
}

.county-details-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.county-details-container.single-column {
    justify-content: center;
}

.county-details-column {
    flex: 1;
    min-width: 200px;
    padding: 10px;
    box-sizing: border-box;
}

.truncate-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.button-group-container {
    display: flex;
    justify-content: center;
    width: 1;
}

.container {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 2rem;
}

.label-bg {
    background-color: #fae0d9;
    border-radius: 10px 10px 0 0;
    padding: 5px 10px;
    margin-bottom: -1px !important;
    width: fit-content;
    font-size: 12px;
    display: inline-block;
}

.form-control {
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
}

.custom-download-button, .custom-dropdown-download-button {
    background-color: #FF6D40 !important;
    color: #FFFFFF !important;
    margin: 5px !important;
    border: none !important;
}

.custom-download-button:hover, .custom-download-button:focus,
.custom-dropdown-download-button:hover, .custom-dropdown-download-button:focus {
    background-color: #e55a33 !important;
    color: #FFFFFF !important;
}

.btn {
    border-radius: 5px;
}

.btn-success {
    background-color: #8BC34A;
    border: none;
}

.btn-primary {
    background-color: #FF6D40;
    border: none;
}

.btn-danger {
    background-color: #d9534f;
    border: none;
}

.notification-item {
    background-color: #F9E1DA;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.Accordion.Header {
    background-color: #fae0d9 !important;
    color: black !important;
    border: none;
    font-weight: bold;
}

.resetBtn {
    background-color: #FFFFFF !important;
    border: 1px solid #FF6D40 !important;
    color: #FF6D40 !important;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    width: 75% !important;
    margin-bottom: 20px;
}

.resetBtn:hover {
    background-color: #812307 !important;
    color: #FFFFFF !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .county-details {
        padding: 10px;
    }
    .county-details-column {
        flex: 1 1 100%;
    }
}

@media (max-width: 767.98px) {
    .table-responsive {
        display: none;
    }
}

/* Hide cards on medium screens and above */
@media (min-width: 768px) {
    .card {
        display: none;
    }
}

.county-section {
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.county-section h4 {
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
}

.county-section p {
    margin: 5px 0;
    color: #555;
}

.county-section p b {
    color: #333;
}

.dropdown-menu {
    z-index: 1060; /* Higher than modal content */
}

.modal-map-container .get-directions-button {
    z-index: 1050; /* Lower than dropdown to ensure dropdown overlays it */
}

.modal-map-container {
    position: relative;
}

