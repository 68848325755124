/* 
   Basic container styling to ensure spacing on all devices,
   with some overrides for narrower screens.
*/
.filter-form-container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }
  
  /* 
     Row styling for the group of filters; 
     you can adjust margins/padding to reduce or increase spacing 
  */
  .filter-form-row {
    margin-bottom: 1rem;
  }
  
  /* 
     Additional row for the reset button
  */
  .button-row {
    margin-top: 0.5rem;
  }
  
  /* 
     Example styling for the label (this 'label-bg' was in your snippet).
     You can remove or change as needed.
  */
  .label-bg {
    font-weight: 600;
    margin-bottom: 0.4rem;
  }
  
  /* 
     Example minimal styling for the reset button, 
     overrides for smaller screens as needed 
  */
  .resetBtn {
    margin-top: 0.5rem;
  }
  
  /* 
     Media query overrides if you want to tweak spacing 
     specifically for small devices, e.g. 
  */
  @media (max-width: 576px) {
    .filter-form-container {
      padding: 0.5rem;
    }
    .filter-form-row {
      margin-bottom: 0.75rem;
    }
    .button-row {
      margin-top: 0.75rem;
    }
    .resetBtn {
      width: 100%;
    }
  }
  