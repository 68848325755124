/* General Styles */
.boards-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

/* Board Card Container Styles */
.board-cards {
    width: 100%; /* Adjust this width as needed */
    max-width: 1200px; /* Adjust this max-width as needed */
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 4 columns */
    grid-gap: 20px; /* Adjust the gap as needed */
}

/* Responsive Design */
@media (max-width: 768px) { /* Adjust breakpoint as needed */
    .board-cards {
        grid-template-columns: 1fr; /* 1 column on smaller screens */
    }
}

/* Board Card Styles */
.board-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    text-align: center; /* Center align board names */
}

.board-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.board-card.selected {
    background-color: #e9e9e9;
}

/* Board Content Styles */
.board-content {
    margin-top: 5px;
}

/* Item Styles */
.item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center; /* Center align item names */
    position: relative; /* For positioning the indicator */
}

.item:hover {
    background-color: #f0f0f0;
    transform: scale(1.03); /* Slight scale up effect */
}

.item.active {
    background-color: #f5f5f5;
}

/* Adding a visual indicator */
.item::after {
    content: '▶'; /* You can use an arrow or any symbol */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #333; /* Adjust color as needed */
    font-size: 12px; /* Adjust size as needed */
}

/* Item Details Dropdown Styles */
.item-details {
    max-width: 100%; /* Ensures the div doesn't exceed the width of its container */
    word-wrap: break-word; /* Breaks long words to prevent horizontal scrolling */
    background-color: #f9f9f9;
}
  
  
  .item-details a {
    word-break: break-all; /* Breaks URLs to prevent them from overflowing */
    overflow-wrap: break-word; /* Alternative to word-break, for better handling of long words */
  }
  

.item-details strong {
    color: #333;
}

.item-details div {
    margin-bottom: 5px;
}

.monday-boards-container {
    padding: 20px;
    margin: 20px auto;
    width: 75%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}