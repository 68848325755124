/* AppItemRedesign.css */

.my-app-item-container {
  width: 150px;
  height: 150px;
  margin: 20px;
  display: inline-block;
  vertical-align: top;
}

.my-app-item-link {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  text-decoration: none; /* remove underline */
}

.my-app-item-logo {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease, transform 0.2s ease, opacity 0.2s ease;
}

.my-app-item-logo:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  opacity: 0.8;
}
