/* LandingPage.module.css */

@media only screen and (max-width: 768px) {
  /* .btn-rounded {
    width: 12rem;
    font-size: 0.9rem;
  } */
  
  .container {
    height: 18rem;
  }

  .mobilebutton {
    font-size: 0.7rem;
  }

  .values-history-container {
    flex-direction: column !important;
  }

  .value-section {
    flex-direction: column !important;
    border-right: none !important;
    border-bottom: 1px solid #f36B3f !important;
    width: 100vw !important;
  }

  .history-section
  {
    flex-direction: column !important;
    border-right: none !important;
    min-width: 75% !important;
  }
}


