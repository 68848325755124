/* 
  Background gradient that gives a subtle modern vibe, 
  and a container centered both vertically and horizontally 
  with a nice space for the spinner and text.
*/
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* Full viewport height for a nice fullscreen effect */
    height: 100vh; 
    width: 100%;
    
    /* Subtle gradient background */
    background: linear-gradient(
      135deg,
      rgba(255, 165, 0, 0.7) 0%,   /* SRI orange with some transparency */
      rgba(255, 165, 0, 0.3) 100%  /* Lighter fade of the same color */
    );
    
    margin: 0; /* Reset default body margin if needed */
  }
  
  /* 
    A spinner that’s slightly larger, 
    uses border technique, but with a fancy glow effect inside.
  */
  .loading-spinner {
    position: relative; 
    width: 80px;
    height: 80px;
    border: 8px solid #f3f3f3; /* Light grey border for contrast */
    border-top: 8px solid #ffa500; /* SRI orange */
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    margin-bottom: 1.5rem;
  }
  
  /* 
    Create a glow effect behind the spinner 
    using a pseudo-element. 
  */
  .spinner-glow {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: #ffa500;
    opacity: 0.4;
    filter: blur(10px);
    z-index: -1; /* behind the spinner */
  }
  
  /* 
    Text that complements the spinner style. 
    You could also add a fancy web-font or animation if you want.
  */
  .loading-text {
    color: #ffffff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    letter-spacing: 1px;
    animation: fadeIn 2s ease-in-out infinite alternate;
  }
  
  /* 
    Keyframes for spinner rotation 
  */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* 
    Simple fade in/out animation for the text 
  */
  @keyframes fadeIn {
    0% { opacity: 0.3; }
    100% { opacity: 1; }
  }
  
  /* 
    Make it responsive for small screens:
    - Slightly smaller spinner
    - Reduced spacing
  */
  @media (max-width: 480px) {
    .loading-spinner {
      width: 60px;
      height: 60px;
      border: 6px solid #f3f3f3;
      border-top: 6px solid #ffa500;
      margin-bottom: 1rem;
    }
  
    .spinner-glow {
      top: -12px;
      left: -12px;
      width: 84px;
      height: 84px;
    }
  
    .loading-text {
      font-size: 1rem;
    }
  }
  