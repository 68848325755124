.form-label {
    background-color: #fae0d9 !important;
    border-radius: 10px 10px 0 0 !important;
    padding: 5px 10px !important;
    margin-bottom: 0 !important;
    width: fit-content !important;
    font-size: 12px !important;
  }



  .form-group {
    margin: 10px !important;
  }

  .no-wrap-cell th, .no-wrap-cell td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Adjust based on your needs */
}


.centered-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  min-width: 300px;
  text-align: center;
}
