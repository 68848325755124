/* DashboardRedesign.css */

/* 1. Main Dashboard Container */
.my-dashboard-container {
  /* Two columns on wider screens; switches to one column in the media query below */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1200px;         /* Constrain width on large desktops */
  margin: 0 auto;            /* Center horizontally */
  box-sizing: border-box;
  overflow-x: hidden;        /* Prevent horizontal scroll if children overflow */
}

/* 2. Individual Dashboard Items (Message, Portal, etc.) */
.my-dashboard-item {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  overflow: hidden;
  width: 100%;               /* Ensure each item can shrink fully */
  box-sizing: border-box;

  /* Modern hover effect */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.my-dashboard-item:hover {
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

/* 3. Admin Control Center (spans entire width in desktop) */
.my-dashboard-control-center {
  /* Spans across both columns on desktop */
  grid-column: 1 / -1;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  padding: 20px;
  background-color: #ffffff; 
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

/* 4. Admin Buttons */
.my-dashboard-control-center > * {
  display: inline-flex;       
  align-items: center;
  gap: 6px;
  padding: 10px 15px;
  background-color: #FF6D40;  /* SRI Orange */
  color: #fff;
  font-weight: bold;
  font-size: 0.95rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.my-dashboard-control-center > * svg {
  font-size: 1.25rem;
}

.my-dashboard-control-center > *:hover,
.my-dashboard-control-center > *:focus {
  background-color: #e65a2d;  /* Slightly darker SRI Orange */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transform: translateY(-2px);
}

/* 5. Responsive rules for tablets / mobile */
@media (max-width: 768px) {
  /* Switch container to a single column */
  .my-dashboard-container {
    grid-template-columns: 1fr !important;
  }

  /* Stack dashboard items vertically */
  .my-dashboard-item {
    margin-bottom: 20px;
  }

  /* Control center also stacks its buttons */
  .my-dashboard-control-center {
    flex-direction: column;
  }
}
