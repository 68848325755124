/* src/components/SaleRegistrationModal.css */

/* Modal Header */
.sale-reg-modal-header {
  background-color: #FF6D40;
  color: white;
}

.sale-reg-modal-title {
  font-weight: bold;
}

.sale-reg-modal-icon {
  margin-right: 8px;
}

/* Modal Body */
.sale-reg-modal-body {
  padding: 20px;
}

.sale-reg-form-group {
  margin-bottom: 15px;
}

/* User Dropdown */
.sale-reg-user-dropdown {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.sale-reg-user-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sale-reg-user-dropdown-item:hover {
  background-color: #FF6D40;
  color: white;
}

/* Loading Spinner */
.sale-reg-loading-spinner {
  margin-top: 10px;
  text-align: center;
}

/* Selected User */
.sale-reg-selected-user {
  margin-top: 20px;
  text-align: center;
}

.sale-reg-selected-user-alert {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sale-reg-confirm-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF6D40;
  border: none;
  color: white;
  transition: background-color 0.3s ease;
}

.sale-reg-confirm-btn:hover,
.sale-reg-confirm-btn:focus {
  background-color: #e65c33;
  color: white;
}

.sale-reg-confirm-btn .fa-user-plus {
  margin-right: 5px;
}

/* Modal Footer */
.sale-reg-modal-footer {
  background-color: #f1f1f1;
}

.sale-reg-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6c757d;
  border: none;
  color: white;
  transition: background-color 0.3s ease;
}

.sale-reg-close-btn:hover,
.sale-reg-close-btn:focus {
  background-color: #5a6268;
  color: white;
}

.sale-reg-close-btn .fa-times {
  margin-right: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sale-reg-modal-body {
    padding: 15px;
  }

  .sale-reg-confirm-btn {
    max-width: 100%;
  }
}
