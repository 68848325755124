.headerContainer {
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    align-items: center;
}

.headerTitle {
    color: white;
    margin-left: 10rem;
}

.contentContainer {
    width: 100%;
    min-height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 7rem;
    padding-right: 7rem;
}

.leftSide {
    width: 40%;
    color: #F26B3F;
}

.leftSideTitle {
    margin-right: 1rem;
}

.rightSide {
    width: 60%;
}

.governmentContainer {
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.whiteText {
    color: white;
}

.whiteSubtitle {
    color: white;
    margin-top: 2rem;
}

.valueContainer {
    width: 100%;
    min-height: 35rem;
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: 10% 75px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #F26B3F;
    padding-left: 7rem;
}

.valueLeftSide {
    width: 40%;
    word-break: break-word;
}

.valueRightSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 2rem;
    color: #F26B3F;
}

.btn {
    background-color: black !important;
    color: white !important;
    margin-top: 2rem !important;
    width: 15rem !important;
    height: 3rem !important;
    text-align: center !important;
}

@media (max-width: 768px) {
    .valueContainer {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }

    .valueLeftSide, .valueRightSide {
        width: 100%;
        padding: 1rem;
        text-align: center; /* optional, if you want to center the text on mobile */
    }

    .contentContainer {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
    }

    .leftSide, .rightSide {
        width: 100%;
        padding: 1rem;
        text-align: center; /* optional, if you want to center the text on mobile */
    }

    .valueContainer {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }

    .valueLeftSide, .valueRightSide {
        width: 100%;
        padding: 1rem;
        text-align: center; /* optional */
    }
}