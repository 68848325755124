/* PropertyDetailsModal.css */

/* Mobile-first Styles */

/* Container for property details */
.property-details-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  /* Individual fields within the container */
  .property-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  
  /* Label styling */
  .property-label {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  
  /* Value styling */
  .property-value {
    word-wrap: break-word;
  }
  
  /* Toggle switch styling */
  .time-toggle-switch {
    margin-bottom: 1rem;
  }
  
  /* Property Address styling */
  .property-address {
    word-wrap: break-word;
    margin-bottom: 1rem;
  }
  
  /* Property Notes Section */
  .property-notes-section {
    margin-top: 1rem;
  }
  
  /* County Information Section */
  .county-section {
    margin-top: 1rem;
  }
  
  /* Documents Section */
  .documents-section {
    margin-top: 1rem;
  }
  
  /* Individual document category */
  .document-category {
    margin-bottom: 0.5rem;
  }
  
  /* Loading state for documents */
  .documents-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF6D40;
    margin: 20px 0;
  }
  
  /* Spinner within documents loading */
  .documents-spinner {
    margin-right: 10px;
  }
  
  /* Full-width dropdown buttons */
  .custom-dropdown-download-button {
    width: 100%;
  }
  
  /* Map container */
  .modal-map-container {
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: 1rem;
  }
  
  /* Google Map styling */
  .modal-map-google-map {
    width: 100%;
    height: 100%;
  }
  
  /* Get Directions button styling */
  .get-directions-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Responsive Styles */
  
  /* Tablet and above */
  @media (min-width: 576px) {
    /* Change property-field to row layout */
    .property-field {
      flex-direction: row;
      align-items: center;
    }
  
    .property-label {
      flex: 0 0 200px; /* Fixed width for labels */
      margin-bottom: 0;
    }
  
    .property-value {
      flex: 1;
    }
  
    /* Adjust map container height */
    .modal-map-container {
      height: 400px;
    }
  
    .get-directions-button {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  /* Desktop and above */
  @media (min-width: 992px) {
    .property-details-container {
      padding: 2rem;
    }
  
    /* Further increase label width */
    .property-label {
      flex: 0 0 220px;
    }
  
    /* Adjust map container height */
    .modal-map-container {
      height: 500px;
    }
  }
  
  /* Typography Adjustments */
  
  /* Ensure the modal has proper padding and font sizes */
  .modal-body {
    padding: 1rem;
  }
  
  .modal-title {
    font-size: 1.5rem;
  }
  
  h4 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  @media (min-width: 576px) {
    .modal-title {
      font-size: 1.75rem;
    }
  
    h4 {
      font-size: 1.5rem;
    }
  }
  
  @media (min-width: 992px) {
    .modal-title {
      font-size: 2rem;
    }
  
    h4 {
      font-size: 1.75rem;
    }
  }
  
  /* Truncate text with ellipsis */
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Copy button styling */
  .copy-button {
    margin-left: 0.5rem;
    cursor: pointer;
  }
  
  /* Adjustments for the document dropdowns */
  .document-category .dropdown-toggle {
    width: 100%;
  }
  