/* src/components/AuctionList.css */

/* 1. Scoped Box Sizing */
.unique-auction-container *,
.unique-auction-container *::before,
.unique-auction-container *::after {
  box-sizing: border-box;
}

/* 2. Auction List Container */
.unique-auction-container {
  width: 100%; /* Utilize full width of the modal */
  margin: 0 auto; /* Center the container without top margin */
  background-color: #f9f9f9;
  border: 2px solid #FF6D40;
  border-radius: 8px;
  padding: 20px;
  overflow-x: hidden; /* Prevents horizontal overflow */
}

/* 3. Top Header */
.unique-auction-top-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FF6D40;
  color: white;
  font-weight: bold;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.unique-auction-header-title {
  flex: 1;
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
}

.unique-auction-download-button {
  background-color: white;
  color: #FF6D40;
  border: 1px solid #FF6D40;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  animation: glowOrange 3s infinite;
  margin-top: 10px;
  max-width: 200px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.unique-auction-download-button .dropdown-menu {
  z-index: 9999;
}

/* 4. Email Preferences Block */
.unique-auction-email-preference {
  margin: 15px auto;
  padding: 15px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #ffe6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.unique-auction-email-preference.enabled {
  background-color: #e6f5e9;
}

.unique-auction-email-preference-label {
  font-size: 16px;
  font-weight: bold;
}

.unique-auction-email-preference-switch {
  margin: 10px 0;
  transform: scale(1.2);
}

.unique-auction-email-preference-status {
  font-size: 14px;
  font-weight: bold;
}

.unique-auction-email-preference-status.enabled {
  color: green;
}

.unique-auction-email-preference-status.disabled {
  color: red;
}

/* 5. Error Alert */
.unique-auction-error-alert {
  margin-top: 10px;
  color: red;
  text-align: center;
}

/* 6. Filters Wrapper */
.unique-auction-filters-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

/* 7. Filters Row */
.unique-auction-filters-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.unique-auction-filter-group {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}

.unique-auction-filter-group label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
}

.unique-auction-filter-select {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* 8. Sort Wrapper */
.unique-auction-sort-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.unique-auction-sort-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.unique-auction-sort-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%;
}

.unique-auction-sort-button {
  flex: 1 1 150px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.unique-auction-sort-button:hover {
  background-color: #0056b3;
}

/* 9. Test Sale Alerts Button */
.unique-auction-alerts-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.unique-auction-alerts-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.unique-auction-alerts-button:hover {
  background-color: #218838;
}

/* 10. Auction Cards Container */
.unique-auction-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  justify-content: center; /* Center cards horizontally */
}

/* 11. Auction Card Styles */
.unique-auction-card-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  width: 100%; /* Full width by default */
  max-width: 300px; /* Maximum width to prevent stretching */
}

.unique-auction-card-header {
  background-color: #FF6D40;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  font-size: 1.1rem;
}

.unique-auction-card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.unique-auction-card-text {
  flex: 1;
  font-size: 14px;
  margin-bottom: 15px;
}

.unique-auction-time-switch {
  margin-bottom: 15px;
}

/* 12. Auction Card Buttons */
.unique-auction-card-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.unique-auction-action-button {
  flex: 1 1 45%;
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.unique-auction-register-zeus-button {
  background-color: #007bff;
  color: white;
}

.unique-auction-register-zeus-button:hover {
  background-color: #0069d9;
}

.unique-auction-register-button {
  background-color: #28a745;
  color: white;
}

.unique-auction-register-button:hover {
  background-color: #218838;
}

.unique-auction-view-sale-button {
  background-color: #17a2b8;
  color: white;
}

.unique-auction-view-sale-button:hover {
  background-color: #138496;
}

/* 13. Location Link Styling */
.unique-auction-location-link {
  color: #FF6D40;
  text-decoration: none;
}

.unique-auction-location-link:hover {
  text-decoration: underline;
}

/* 14. Download Button Animation */
@keyframes glowOrange {
  0%, 100% { background-color: white; color: #FF6D40; }
  50% { background-color: #FF6D40; color: white; }
}

/* 15. Responsive Design */

/* Tablet: ≥576px and <768px */
@media (min-width: 576px) and (max-width: 767.98px) {
  .unique-auction-sort-button-group {
    flex-wrap: wrap;
    justify-content: center;
  }

  .unique-auction-action-button {
    flex: 1 1 100%;
  }
}

/* Mobile: <576px */
@media (max-width: 575.98px) {
  .unique-auction-container {
    padding: 10px;
  }

  .unique-auction-top-header {
    padding: 10px;
  }

  .unique-auction-header-title {
    font-size: 1.3rem;
  }

  .unique-auction-download-button {
    padding: 0.5rem 1rem;
    margin-top: 10px;
  }

  .unique-auction-filters-row {
    flex-direction: column;
    align-items: center;
  }

  .unique-auction-filter-group {
    width: 100%;
    max-width: 100%;
  }

  .unique-auction-sort-button-group {
    flex-direction: column;
    gap: 10px;
  }

  .unique-auction-sort-button {
    flex: 1 1 100%;
  }

  .unique-auction-card-item {
    padding: 10px;
    margin-bottom: 1rem; /* Ensures spacing between stacked cards */
    max-width: 100%; /* Full width on small screens */
  }

  .unique-auction-card-header {
    font-size: 1rem;
    padding: 10px;
  }

  .unique-auction-card-body {
    padding: 10px;
  }

  .unique-auction-card-text {
    font-size: 13px;
  }

  .unique-auction-time-switch {
    font-size: 13px;
  }

  .unique-auction-action-button {
    flex: 1 1 100%;
  }

  .unique-auction-alerts-button {
    width: 100%;
    max-width: 300px;
  }
}

/* 16. Modal Specific Styles to Prevent Overlapping */

/* Unique classname to scope modal styles */
.unique-auction-modal-dialog {
  max-width: 95%; /* Ensures modal doesn't exceed viewport width */
}

.unique-auction-modal-body {
  padding: 20px;
  overflow-y: auto;
  max-height: 80vh; /* Ensures the modal doesn't exceed viewport height */
}

.unique-auction-modal-header,
.unique-auction-modal-footer {
  background-color: #FF6D40;
  color: white;
}

.unique-auction-modal-footer {
  justify-content: flex-end;
}

/* 17. Prevent Overlapping Between 578px and 1200px */

/* Target the range between 578px and 1200px */
@media (min-width: 578px) and (max-width: 1200px) {
  .unique-auction-card-item {
    margin-bottom: 1.5rem; /* Ensures spacing between stacked cards */
  }

  .unique-auction-action-button {
    flex: 1 1 45%; /* Ensures buttons take up roughly half the card width */
  }
}

/* Ensure Images are Responsive */
.unique-auction-card-item img,
.unique-auction-location-link a {
  max-width: 100%;
  height: auto;
  display: block;
}

/* 18. Fixed Positioning for Toasts */
.unique-auction-toast-container {
  position: fixed !important; /* Override any existing positioning */
  top: 1rem; /* Distance from the top of the viewport */
  right: 1rem; /* Distance from the right of the viewport */
  z-index: 1060; /* Ensure it appears above other elements (Bootstrap modals use 1050) */
}

/* Optional: Adjust Toast styling if needed */
.unique-auction-toast-container .toast {
  min-width: 250px; /* Ensure the toast is wide enough */
}
